.heading,
:--layout-dark .heading,
:--group-dark .heading {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .heading,
:--group-light .heading {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

.heading:first-child,
* + .heading {
  margin-top: var(--section-gap);
}
.layout__body > .heading:first-child {
  margin-top: calc(var(--header-height) + var(--section-gap));
}
.layout__body > .heading--background:first-child {
  margin-top: var(--header-height);
}

.heading {
  position: relative;

  overflow: hidden;

  font-family: var(--font-family-title);
  text-align: center;
}

.heading__background--left {
  position: absolute;
  left: 0;

  display: block;

  pointer-events: none;
  user-select: none;
}

.heading__background--left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.heading__background--right {
  position: absolute;
  right: 0;

  display: block;

  pointer-events: none;
  user-select: none;
}

.heading__background--right img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1500px) {
  .heading__background--left {
    transform: translate(-50%, 0);
  }
  .heading__background--right {
    transform: translate(50%, 0);
  }
}

@media (--mobile) {
  .heading__background--left {
    transform: translate(-55%, 0);
  }
  .heading__background--right {
    transform: translate(60%, 0);
  }
}

.heading__container {
  margin-right: auto;
  margin-left: auto;
}

.heading--background .heading__container {
  padding: 163px 0;
}

.heading__title {
  font-size: var(--heading-font-size);
  font-weight: 700;
  line-height: 1.35;
  letter-spacing: 0;
  color: var(--color);
  text-align: center;
}
.heading__title strong {
  color: var(--primary-500);
}
.heading--left-title .heading__title {
  text-align: left;
}
* + .heading__title {
  margin-top: 8px;
}
@media (--mobile) {
  .heading__title {
    font-size: 24px;
    line-height: 140%;
    letter-spacing: 1.92;
  }
  .heading__title br {
    display: none;
  }
}

.heading__container__bubbles {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 32px;
}

.heading__container__bubbles__bubble {
  width: 10px;
  height: 10px;

  border-radius: 50%;
  background-color: var(--primary-500);
}

.heading__description {
  max-width: 70ch;
  margin-right: auto;
  margin-left: auto;

  font-size: 18px;
  font-weight: 400;

  line-height: 130%;

  color: rgba(var(--color-rgb), 0.8);

  text-align: center;
}
.heading--left-title .heading__description {
  margin-right: 0;
  margin-left: 0;
  text-align: left;
}
.heading__description strong {
  color: var(--primary-500);
}
* + .heading__description {
  margin-top: 12px;
}
@media (--mobile) {
  .heading__description {
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
  }
  * + .heading__description {
    margin-top: 16px;
  }
}

.heading__sub__content {
  margin-top: 32px;

  font-size: 24px;

  font-weight: 500;
  line-height: 130%;

  color: var(--primary-500);
  text-align: center;
}
@media (--mobile) {
  .heading__sub__content {
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.36;
  }
}
