.form-dialog__overlay {
  position: fixed;
  z-index: var(--z-index-dialog-overlay);

  background-color: rgba(0, 0, 0, 0.8);
  inset: 0;
  animation: form-dialog-overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.form-dialog__content {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: var(--z-index-dialog-content);

  width: 90vw;
  max-width: 500px;
  max-height: 85vh;
  padding: 25px;

  color: black;

  border-radius: 6px;

  /* box-shadow: var(--shadow-6); */
  background-color: white;
  transform: translate(-50%, -50%);
  animation: form-dialog-content-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.form-dialog__content:focus {
  outline: none;
}

.form-dialog__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
}

.form-dialog__title {
  margin: 0;

  font-size: 20px;
  font-weight: 500;
}

.form-dialog__description {
  margin: 10px 0 20px;

  font-size: 15px;
  line-height: 1.5;
}

.form-dialog__icon-button {
  --size: 24px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);

  font-family: inherit;

  border-radius: 100%;
  cursor: pointer;
}
.form-dialog__icon-button svg {
  width: 100%;
  height: 100%;
}

.form-dialog__field-set {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}

@keyframes form-dialog-overlay-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes form-dialog-content-show {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.form-dialog__form {
  --gap: 8px;
}
* + .form-dialog__form {
  margin-top: 32px;
}
@media (--tablet) {
  * + .form-dialog__form {
    margin-top: 16px;
  }
}

.form-dialog__line {
  display: flex;
  gap: var(--gap);
}
* + .form-dialog__line {
  margin-top: var(--gap);
}

.form-dialog__line > .field {
  flex: 1;
}

.form-dialog__cta {
  --height: 42px;
  width: 100%;
  text-align: center;
}
* + .form-dialog__cta {
  margin-top: 32px;
}
@media (--mobile) {
  * + .form-dialog__cta {
    margin-top: 24px;
  }
}

.form-dialog__cta .button__text {
  font-size: 14px;
  font-weight: 600;
}

.form-dialog__error {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--primary-500);
}
* + .form-dialog__error {
  margin-top: 12px;
}
