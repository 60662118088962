.hero-formation {
  width: 100%;
}

.hero-formation__wrap {
  --gap: 64px;

  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap);
}
@media (--tablet) {
  .hero-formation__wrap {
    --gap: 32px;

    grid-template-columns: 1fr;
  }
}

.hero-formation__image {
  height: 100%;
}
@media (--mobile) {
  .hero-formation__image {
    height: 350px;
  }
}

.hero-formation__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-formation__text {
  max-width: calc(
    (
        var(--page-max-width) + var(--page-left-padding) +
          var(--page-right-padding) - var(--gap)
      ) / 2
  );
  padding-top: 64px;
  padding-right: var(--page-right-padding);
  padding-bottom: 72px;
}
@media (--tablet) {
  .hero-formation__text {
    max-width: 100%;
    padding-top: 0;
    padding-bottom: var(--section-gap);
    padding-left: var(--page-left-padding);
  }
}

.hero-formation__type {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--primary-500);
  text-transform: uppercase;
}
@media (--mobile) {
  .hero-formation__type {
    font-size: 15px;
  }
}

.hero-formation__title {
  max-width: 35ch;

  font-size: 34px;
  font-weight: 700;
  line-height: 1.3;
  color: var(--white);
}
* + .hero-formation__title {
  margin-top: 32px;
}
@media (--tablet) {
  .hero-formation__title {
    font-size: 28px;
  }
}
@media (--mobile) {
  .hero-formation__title {
    font-size: 22px;
  }
  * + .hero-formation__title {
    margin-top: 16px;
  }
}

.hero-formation__price {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
* + .hero-formation__price {
  margin-top: 32px;
}
@media (--mobile) {
  .hero-formation__price {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  * + .hero-formation__price {
    margin-top: 24px;
  }
}

.hero-formation__price__amount {
  font-size: 32px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: var(--light);
  white-space: nowrap;
}
.hero-formation__price__amount sub {
  bottom: unset;

  font-size: 20px;
}

.hero-formation__price__note {
  font-size: 24px;

  font-weight: 500;
  line-height: 130%; /* 31.2px */
  letter-spacing: 0.01em;
  color: var(--primary-500);
}
.hero-formation__price__note--tag {
  display: inline-block;
  margin-top: 3px;
  padding-top: 6px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;

  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: var(--primary-500);

  border: 1px solid var(--primary-500);
  border-radius: 8px;
  background: linear-gradient(
    89deg,
    rgba(var(--primary-500-rgb), 0.2),
    rgba(var(--primary-500-rgb), 0.05)
  );
  text-align: left;
}

.hero-formation__price span u {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 31.2px */
  letter-spacing: 0.01em;
  color: var(--primary-500);
  text-decoration-line: line-through;
}

.hero-formation__content {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: rgba(var(--light-rgb), 0.8);
}
* + .hero-formation__content {
  margin-top: 32px;
}

.hero-formation__content * + p,
.hero-formation__content * + ul {
  margin-top: 1em;
}

.hero-formation__content ul li {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.03em;
}
.hero-formation__content ul * + li {
  margin-top: 16px;
}

.hero-formation__content ul li::before {
  --size: 20px;

  content: '';
  flex-shrink: 0;
  width: var(--size);
  height: var(--size);
  margin-top: 2px;

  background-image: url('/icons/red-check.svg');
  background-size: var(--size);
}

* + .hero-formation__form {
  margin-top: 44px;
}
.hero-formation__form .inline-form__input {
  max-width: 100%;
}

.hero-formation__cta {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
* + .hero-formation__cta {
  margin-top: 44px;
}
.hero-formation__cta + .hero-formation__cta {
  margin-top: 16px;
}
@media (--mobile) {
  * + .hero-formation__cta {
    margin-top: 32px;
  }
  .hero-formation__cta + .hero-formation__cta {
    margin-top: 16px;
  }
}

.hero-formation__cta .button {
  width: 100%;
  text-align: center;
}

* + .hero-formation__countdown {
  margin-top: 52px;
}
@media (--mobile) {
  * + .hero-formation__countdown {
    margin-top: 32px;
  }
}

.hero-formation__info {
  font-size: 14px;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.01em;
  color: rgba(var(--light-rgb), 0.8);
  text-align: center;
}
* + .hero-formation__info {
  margin-top: 16px;
}
