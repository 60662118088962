.hero-about {
}

.hero-about .max-width {
  position: relative;
}

.hero-about__image {
  position: absolute;
  top: 40px;
  right: calc(50% - 20px);
  bottom: 0;
  left: -150px;
}
@media (--tablet) {
  .hero-about__image {
    top: auto;
    right: -50px;
    bottom: -40px;
    left: auto;

    height: 400px;
  }
}
@media (--mobile) {
  .hero-about__image {
    height: 300px;
  }
}

.hero-about__image img {
  display: block;
  width: auto;
  height: 100%;
  object-fit: contain;
  object-position: bottom center;
}
@media (--tablet) {
  .hero-about__image img {
    object-position: bottom right;
  }
}

.hero-about__text {
  position: relative;

  width: calc(50% - 50px);
  margin-left: auto;

  padding-top: 100px;
  padding-bottom: 100px;
}
@media (--page-max-width) {
  .hero-about__text {
    padding-top: 64px;
    padding-bottom: 72px;
  }
}
@media (--tablet) {
  .hero-about__text {
    width: 100%;
    padding-top: 32px;
    padding-bottom: 300px;
  }
}

.hero-about__title {
  font-size: 42px;
  font-weight: 700;
  line-height: 1.3;
  color: var(--light);
}
.hero-about__title strong {
  color: var(--primary-500);
}
@media (--page-max-width) {
  .hero-about__title {
    font-size: 38px;
  }
}
@media (--tablet) {
  .hero-about__title {
    font-size: 32px;
  }
}
@media (--mobile) {
  .hero-about__title {
    font-size: 28px;
  }
}

.hero-about__content {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #f8f8f8;
}
* + .hero-about__content {
  margin-top: 32px;
}
@media (--tablet) {
  .hero-about__content {
    max-width: 60ch;
  }
  * + .hero-about__content {
    margin-top: 24px;
  }
}

.hero-about__content * + p {
  margin-top: 1em;
}

.hero-about__signatures {
  width: 320px;
  height: auto;
}
* + .hero-about__signatures {
  margin-top: 32px;
}
@media (--mobile) {
  .hero-about__signatures {
    width: 270px;
  }
}
