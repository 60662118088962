.hero-formation__slider {
  position: relative;

  width: 100%;
  height: 100%;
}

@media (--tablet) {
  .hero-formation__slider {
    height: 450px;
  }
}

@media (--tablet) {
  .hero-formation__slider {
    height: 300px;
  }
}

.hero-formation__slider__cards {
  position: relative;

  width: 100%;
  height: 100%;
}

.hero-formation__slider__card {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.hero-formation__slider__card__video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-formation__slider__card__video-overlay img {
  --icon-size: 112px;

  width: var(--icon-size);
  height: var(--icon-size);
}

@media (--tablet) {
  .hero-formation__slider__card__video-overlay img {
    --icon-size: 90px;
  }
}

@media (--mobile) {
  .hero-formation__slider__card__video-overlay img {
    --icon-size: 70px;
  }
}

.hero-formation__slider__card video {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-formation__slider__card--current {
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.hero-formation__slider__preview-cards {
  --offset-side: 64px;
  --offset-bottom: 48px;
  --cards-gap: 15px;

  position: absolute !important;
  right: var(--offset-side);
  bottom: var(--offset-bottom);
  z-index: 2;

  display: flex;
  justify-content: right;
  gap: 24px;

  width: calc(
    var(--page-max-width) / 2 - var(--offset-side) - (var(--gap) / 2)
  );
}

@media (--tablet) {
  .hero-formation__slider__preview-cards {
    right: var(--page-right-padding);
    left: var(--page-left-padding);

    width: 100%;
  }
}

.hero-formation__slider__preview-cards__wrap {
  gap: var(--cards-gap);
  list-style: none;
}

.hero-formation__slider--loaded .hero-formation__slider__preview-cards__wrap {
  gap: 0;
}

.hero-formation__slider__preview-card {
  --card-size: 100px;
  position: relative;

  overflow: hidden;

  max-width: var(--card-size);
  min-width: var(--card-size);
  height: var(--card-size);

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 6px;
}

@media (--tablet) {
  .hero-formation__slider__preview-cards {
    --offset-bottom: 20px;
  }
  .hero-formation__slider__preview-card {
    --card-size: 50px;
  }
}

@media (--mobile) {
  .hero-formation__slider__preview-cards {
    --offset-bottom: 30px;
  }
  .hero-formation__slider__preview-card {
    --card-size: 70px;
  }
}

.hero-formation__slider__preview-card--current {
  border: 1px solid rgb(255, 255, 255);
}

.hero-formation__slider__preview-card > img,
.hero-formation__slider__card > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-formation__slider__preview-card--is-video > img:first-of-type {
  --icon-size: 32px;

  position: absolute;
  top: calc(50% - (var(--icon-size) / 2));
  right: 0;
  bottom: 0;
  left: calc(50% - (var(--icon-size) / 2));
  z-index: 2;

  width: var(--icon-size);
  height: var(--icon-size);
}

.hero-formation__slider__card__video-pause-icon,
.hero-formation__slider__card__video-play-icon,
.hero-formation__slider__card__video-preview {
  display: none;
}

.hero-formation__slider__card__video-pause-icon--show,
.hero-formation__slider__card__video-play-icon--show,
.hero-formation__slider__card__video-preview--show {
  display: block;
}
