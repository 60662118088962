.hero-home {
  --aspect-ratio: 56.25%;

  position: relative;
}
@media (--tablet) {
  .hero-home {
    height: unset;
  }
}

.hero-home .max-width {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  gap: 32px;
  padding-top: 80px;
  padding-bottom: 100px;
}
.home-hero--illustration-large .max-width {
  padding-top: 50px;
  padding-bottom: 30px;
}
@media (--tablet) {
  .hero-home .max-width {
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    padding-top: 40px;
    padding-bottom: 70px;
  }
  .home-hero--illustration-large .max-width {
    padding-bottom: 30px;
  }
}
@media (--mobile) {
  .hero-home .max-width {
    gap: 32px;
    padding-top: 16px;
    padding-bottom: 32px;
  }
  .home-hero--illustration-large .max-width {
    padding-bottom: 16px;
  }
}

.hero-home__background__left {
  position: absolute;
  top: 0;
  left: 0;

  width: fit-content;

  height: 100%;
  object-fit: contain;
}
@media (--mobile) {
  .hero-home__background__left {
    display: none;
  }
}

.hero-home__background__right {
  position: absolute;
  right: 0;
  bottom: 0;

  width: fit-content;
  height: 100%;
  object-fit: contain;
}
@media (--mobile) {
  .hero-home__background__right {
    opacity: 0.5;
  }
}

.hero-home__title {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  color: var(--white);
}
.hero-home__title strong {
  font-weight: 700;
  color: var(--primary-500);
}
* + .hero-home__title {
  margin-top: 8px;
}
@media (--page-max-width) {
  .hero-home__title {
    font-size: 34px;
  }
}
@media (--mobile) {
  .hero-home__title {
    max-width: 32ch;

    font-size: 24px;
    text-align: left;
  }
  .hero-home__title br {
    display: none;
  }
}

.hero-home__content {
  max-width: 38ch;

  font-size: 24px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: var(--light);
  opacity: 0.8;
}
* + .hero-home__content {
  margin-top: 18px;
}
@media (--page-max-width) {
  .hero-home__content {
    font-size: 20px;
  }
}
@media (--mobile) {
  .hero-home__content {
    font-size: 16px;
  }
  * + .hero-home__content {
    margin-top: 12px;
  }
}

.hero-home__content * + p {
  margin-top: 1.5em;
}

.hero-home__content img {
  max-width: 50%;
}
@media (--mobile) {
  .hero-home__content img {
    max-width: 100%;
  }
}

.hero-home__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
* + .hero-home__buttons {
  margin-top: 32px;
}
@media (--mobile) {
  .hero-home__buttons {
    display: block;
  }
  * + .hero-home__buttons {
    margin-top: 24px;
  }
  .hero-home__buttons .button {
    width: 100%;
    text-align: center;
  }
  .hero-home__buttons * + .button {
    margin-top: 12px;
  }
}

.hero-home__image {
  position: relative;

  flex: 1;
  width: auto;
  height: 328px;
}
.home-hero--illustration-large .hero-home__image {
  height: auto;
}

.hero-home__image__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-hero--illustration-large .hero-home__image__image {
  width: 100%;
  height: auto;
}

.hero-home__video__player__container {
  flex: 1;
  width: 100%;
}
@media (--tablet) {
  .hero-home__video__player__container {
    max-width: 800px;
  }
}

.hero-home__video__player__wrap {
  position: relative;

  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  border-radius: 8px;
  background-color: var(--dark);
}
@media (--mobile) {
  .hero-home__video__player__wrap {
    width: auto;
  }
}

.hero-home__video__player__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.hero-home__video__player__overlay__button {
  flex-shrink: 0;
  width: 128px;
  height: 100px;
  padding: 19px 32px;

  border-radius: 16px;
  background-color: rgba(var(--white-rgb), 0.2);
  cursor: pointer;
  transition:
    200ms background-color,
    200ms opacity;
}
.hero-home__video__player__overlay__button::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}
.hero-home__video__player__overlay__button:hover {
  background-color: rgba(var(--white-rgb), 0.3);
}
.hero-home__video__player__overlay__button:active {
  background-color: rgba(var(--white-rgb), 0.2);
}
.hero-home--playing .hero-home__video__player__overlay__button {
  opacity: 0;
}

.hero-home__video__player__video {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.hero-home__text {
  flex: 1;
  width: 100%;
}
